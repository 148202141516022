





























import { Component, Vue, Watch } from 'vue-property-decorator';
import I18n from '@/i18n/index';

export type Inquiry = {
  company: string;
  sei: string;
  mei: string;
  seikana: string;
  meikana: string;
  mail: string;
  phone1: string;
  phone2: string;
  phone3: string;
  type: string;
  detail: string;
};

@Component({
  components: {},
})
export default class Index extends Vue {
  private i18n = I18n;
  inquiry: Inquiry = {
    company: '',
    sei: '',
    mei: '',
    seikana: '',
    meikana: '',
    mail: '',
    phone1: '',
    phone2: '',
    phone3: '',
    type: '',
    detail: '',
  };
}
